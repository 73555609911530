import React, {useEffect, useState, useRef} from 'react';
import {Popover, OverlayTrigger, Overlay, Tooltip} from "react-bootstrap";
import {__, forceSatoshiFormat} from "../../../../Helper";
import stroage from "../../../../Storage";

function CreditRange(props) {
    const [show, setShow] = useState(false);
    const [target, setTarget] = useState(null);
    const ref = useRef(null);

    const handleClick = (event) => {
        event.preventDefault()
        setShow(!show);
        setTarget(event.target);
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const style = {
        borderColor: "transparent",
        borderWidth: "1px",
        borderStyle: "solid",
        borderRadius: '2px',
        flexWrap: 'nowrap',
    }

    return (
        <div ref={ref}>
            <button style={{width: '100%', backgroundColor: '#FFCF14', color: 'white'}} onClick={handleClick} className={'btn btn-lg shadow-none h-100'}><i className="mdi mdi-unfold-more-vertical"/></button>
            <Overlay
                show={show}
                target={target}
                placement="top"
                container={ref.current}
                containerPadding={20}
                rootClose={true}
            >
                <Popover id="popover-contained">
                    <Popover.Content className="p-0">
                        <div class="input-group" style={style}>
              <span class="input-group-prepend">
                <button disabled={props.amountDisabled} style={{backgroundColor: '#FFCF14', width: '100%', color: 'white'}} type="button"
                        class="btn btn-xs shadow-none rounded-0" onClick={e => props.min(e)}>MIN</button>
              </span>
                            <span class="input-group-prepend">
                <button disabled={props.amountDisabled} style={{backgroundColor: '#FFCF14', width: '100%', color: 'white'}} type="button"
                        class="btn btn-xs shadow-none rounded-0" onClick={e => props.multi(e)}>X2</button>
              </span>
                            <span class="input-group-prepend">
                <button disabled={props.amountDisabled} style={{backgroundColor: '#FFCF14', width: '100%', color: 'white'}} type="button"
                        class="btn btn-xs shadow-none rounded-0" onClick={e => props.devide(e)}>/2</button>
              </span>
                            <span class="input-group-append">
                <button disabled={props.amountDisabled} style={{backgroundColor: '#FFCF14', width: '100%', color: 'white'}} type="button"
                        class="btn btn-xs shadow-none rounded-0" onClick={e => props.max(e)}>MAX</button>
              </span>
                        </div>
                    </Popover.Content>
                </Popover>
            </Overlay>
        </div>
    );
}

export default CreditRange;