import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal, Row, Col} from "react-bootstrap";
import storage from "../../../../Storage";
import RangeCredit from "../../../Components/Game/Addons";
import Engine from "../Engine";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";
import {setWinnerText, setMeToGame} from "../../../../actions/crashGame";
import {Event, __, isValidNumber, forceSatoshiFormat, User, wait, Game, sendNotfication, toComma} from "../../../../Helper";

class ManualBet extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonText: 'BET (Next Round)',
            buttonType: 'btn-bet',
            inputDisabled: false,
            amountDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            payout: storage.getKey('payout') ? storage.getKey('payout') : '',
            amount: storage.getKey('amount') ? storage.getKey('amount') : 10.00,
            target_profit: '',
            winning_chance: '',
            token: storage.getKey('token') ? storage.getKey('token') : null,
            hotkey: storage.getKey('hotkey') ? storage.getKey('hotkey') : "OFF",
            autoRunning: false,
            autoHeader: 'Auto',
            autoBody: 'text-danger',
            autoColor: '#FFCF14',
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.setBet = this.setBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine;

            this.setState({engine: engine});

            //Load Coin From Redux
            this.props.gameCoin();

            window.addEventListener('keypress', this.handleHotKey.bind(this));
            document.addEventListener('mousedown', this.handleClickOutside);

            // Game Event
            engine.trigger.on("game_status", (data) => this.checkStatus(data));

            engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
            engine.trigger.on("busted_crash", () => this.checkBustedGame());
            engine.trigger.on("started_crash", () => this.checkStartedGame());

            // User Event
            engine.trigger.on("play_crash", data => this.handlePlay(data));
            engine.trigger.on("finish_crash", data => this.handleFinish(data));

            //Error
            engine.trigger.on("error_crash", data => this.handleError(data));

            // Stop manual bet
            engine.trigger.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            //engine.trigger.on("auto_bet", data => this.handleAuto(data));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener('keypress', this.handleHotKey.bind(this));
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    setTP(payout, amount) {
        let my_target_profit = parseFloat((payout * amount) - amount).toFixed(2);
        if (my_target_profit === 'NaN' || my_target_profit <= 0) {
            my_target_profit = '~';
        } else {
            my_target_profit = toComma(my_target_profit);
        }
        this.setState({target_profit: my_target_profit});
    }

    setWC(payout) {
        let my_wc = parseFloat(99 / payout).toFixed(2);
        if (my_wc === 'NaN' || my_wc <= 0 || my_wc === 'Infinity') {
            my_wc = '~';
        } else {
            my_wc += '%';
        }
        this.setState({winning_chance: my_wc});
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {

            //payout
            let value = parseFloat(this.state.payout);
            if (this.state.payout.length === 0 || value === 'NaN') {
                //optional
            } else {
                if (value < 1.10) {
                    value = 1.10;
                }

                if (value > 1000000) {
                    value = 1000000;
                }
            }

            this.setState({payout: parseFloat(value).toFixed(2)});

            //amount
            let amount = this.wrapperRef.current.value;

            amount = Math.max(10.00, parseFloat(amount));
            if (amount > 3000) {
                //amount = 3000; //maxbet - 3000 | remember to also set in the input
            }

            this.setState({amount})

            if (amount === "NaN") {
                amount = 10.00;
            }
            this.setState({amount});

            //set advice values
            this.setTP(value, amount);
            this.setWC(value);
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (name === 'amount') {
            if (!isValidNumber(value)) return;

            this.setTP(this.state.payout, value);
            storage.setKey("amount", value);
        }

        if (name === 'payout') {
            if (value >= 999999) return;

            this.setTP(value, this.state.amount);
            this.setWC(value);
            storage.setKey("payout", value);
        }

        this.setState({[name]: value});
    }

    stopManual() {
        if (this.state.autoRunning) {
            this.props.toggleSpecial(true);
        } else {
            this.props.toggleSpecial(false);
        }

        let nextText = (this.state.autoRunning) ? 'Automatic...' : 'BET (Next Round)';
        this.setState({started: false, buttonText: nextText, inputDisabled: false, amountDisabled: false});
    }

    checkStatus(data) {
        if (this._isMounted) {
            //console.log("game status, data:", data);

            switch (data.status) {
                case 'waiting':
                    this.checkWaitingGame();
                    break;
                case 'started':
                    wait(1000).then(() => {
                        this.checkStartedGame(data);
                    });
                    break;
                case 'busted':
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleAuto = (data) => {
        if (this._isMounted) {

            let {amount, payout} = data;

            //console.log("handle auto: st -", this.state.gameStatus, " | data - ", data);

            if (!payout) return alert('payout is empty.');
            if (!amount) return alert('amount is empty.');

            this.setState({amount: amount, payout: payout});

            if (this.state.gameStatus === 'started') {
                this.holdBet();
            } else if (this.state.gameStatus === 'waiting' || this.state.gameStatus === 'busted') {
                this.setBet();
            }
        }
    };

    handleError(data) {
        if (this._isMounted) {
            this.props.setMeToGame(false);

            this.setState({holding: false, added: false, inputDisabled: false, amountDisabled: false});
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                if (data.code === 'credit') {
                    this.props.setWallet(true, data.uid);
                }
            }

            this.setState({clicked: false})
            sendNotfication(data.message, 'danger', 'top-center');

            if (!__.isUndefined(data.code)) {
                if (data.code === 'version') {
                    wait(3000).then(() => {
                        window.location.reload();
                    });
                }
            }
        }
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);

            if (this.state.autoRunning) {
                this.props.toggleSpecial(true);
            } else {
                this.props.toggleSpecial(false);
            }

            let nextText = (this.state.autoRunning) ? 'Automatic...' : 'BET (Next Round)';
            this.setState({added: false, holding: false, inputDisabled: false, amountDisabled: false, buttonType: 'btn-bet', buttonText: nextText});
        }
    };

    setWaitingButton = () => {
        if (this._isMounted) {

            this.props.toggleSpecial(true);

            this.setState({added: true, inputDisabled: true, amountDisabled: true, buttonType: 'btn-bet-success-crash text-white btn-p no-shadow', buttonText: "Please Wait..."});
        }
    };

    setOutButton = () => {
        if (this._isMounted) {

            this.props.toggleSpecial(true);

            this.setState({inputDisabled: false, amountDisabled: true, buttonType: 'btn-bet-success', buttonText: "CANCEL (Next Round)"});
        }
    };

    setBet() {
        if (this._isMounted) {
            this.setState({clicked: true});
            let {engine, amount, payout, token} = this.state;
            engine.coin = this.props.coin;
            engine.token = token;
            engine.amount = amount;
            engine.payout = payout === '' ? '' : parseInt(payout * 100);
            engine.type = 'normal';
            engine.button = 'normal';
            engine.play();
        }
    }

    cashOut() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.finish(Game['current_amount']);
        }
    }

    handlePlay(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                this.props.setMeToGame(true);
            }
        }
    }

    handleFinish(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                clearInterval(this.state.buttonProgress);
                this.props.setWinnerText("  You Cashed Out @" + data.current / 100);
                this.props.setMeToGame(false);
                this.setDefaultButton();
            }
        }
    }

    checkWaitingGame() {
        if (this._isMounted) {
            //auto
            this.checkAutoRunning();

            this.props.setWinnerText('');

            this.setState({gameStatus: 'waiting'});

            if (this.state.holding) {
                this.setState({holding: false});
                this.placeBet();
            }
        }
    }

    checkStartedGame(data = null) {
        if (this._isMounted) {
            let {im_in_game} = this.props;
            let {engine} = this.state;

            let coin = engine.coin;
            let amount = engine.amount

            this.setState({gameStatus: 'started'});

            if (data != null) {
                //console.log("check started, players:", data.players.length);
                if (data.players.length > 0) {
                    data.players.forEach((the_player, i) => {
                        if (the_player.uid === 4153751612) {
                            //this.setState({clicked: true});
                        }
                        //console.log("check started, uid:", the_player.uid, ' >>', this.state.clicked);
                    });
                }
            }

            if (im_in_game === true || this.state.clicked) {

                this.props.toggleSpecial(true);

                this.setState({
                    inputDisabled: true,
                    amountDisabled: false,
                    buttonType: "btn-bet-success-crash",
                    clicked: false
                });

                let counter = 0;
                let self = this;
                this.state.buttonProgress = setInterval(function () {
                        //let calc = amount * (Game['current_amount'] - 1);
                        let current_amount = (Game['current_amount']);
                        if (current_amount >= 1.1) {
                            self.setState({inputDisabled: false});
                        }

                        let calc = amount * current_amount; //Dan
                        self.setState({buttonText: 'Cashout@' + current_amount + ' = ' + forceSatoshiFormat(calc) + ' KES'});
                        counter++;
                    }
                        .bind(this),
                    50
                );
            }
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({gameStatus: 'busted', clicked: false});

            let {im_in_game} = this.props;

            if (!this.state.holding) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if (im_in_game) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    isCorrectBet() {
        let {amount, payout} = this.state;

        if (!isValidNumber(amount))
            return false;

        if (amount < 10) {
            return false;
        }
        //todo - payout check
        return true;
    }

    placeBet() {
        if (this._isMounted) {
            let {engine} = this.state;

            engine.isHolding = false;
            this.setWaitingButton();
            this.setBet();
        }
    }

    holdBet() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.isHolding = true;
            this.setState({holding: true});
            this.setOutButton();
        }
    }

    handleBet(e) {
        if (this._isMounted) {
            e.preventDefault();

            let {amount, payout, holding, gameStatus, token} = this.state;
            let {im_in_game} = this.props;

            // Check User
            if (!token) {
                return Event.emit('showAuthModal', true);
            }

            // Check is Correct Bet
            if (!this.isCorrectBet()) {
                return false;
            }

            if (amount < 10) {
                return false;
            }

            if (payout !== '') {
                this.setState({payout: (payout * 1).toFixed(2)});
            }

            // Check Game Status to Play
            switch (gameStatus) {
                case 'waiting':
                    this.placeBet();
                    break;

                case 'busted':
                    if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;

                case 'started':
                    if (im_in_game) {
                        this.cashOut();
                    } else if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;
            }
        }
    }

    hotkeyChange() {
        if (this._isMounted) {
            if (this.state.hotkey === "OFF") {
                User['denied_hotkey'] = false;
                storage.setKey("hotkey", 'ON');
                this.setState({hotkey: "ON"})
            } else {
                storage.setKey("hotkey", 'OFF');
                this.setState({hotkey: "OFF"})
            }
        }
    }

    handleHotKey(e) {
        if (this._isMounted) {
            if (User['denied_hotkey']) {
                User['denied_hotkey'] = false;
                storage.setKey('hotkey', "OFF");
                this.setState({hotkey: 'OFF'})
            }

            if (storage.getKey('hotkey') === "ON") {
                let char = e.which || e.keyCode;
                if (char === 98) {
                    this.handleBet(e);
                }
                if (char === 102) {
                    let calc = parseFloat(this.state.payout * 2);
                    let max = Math.max(1.00, calc);
                    this.setState({payout: max.toFixed(2)})
                }
                if (char === 101) {
                    let calc = parseFloat(this.state.payout / 2);
                    let max = Math.max(1.00, calc);
                    this.setState({payout: max.toFixed(2)})
                }

                this.handleInputChange(e);
            }
        }
    }

    setMax = (e) => {
        e.preventDefault();
        let max = this.props.credit;
        if (max === null) return;
        if (max === 'NaN') return;

        max = Math.min(max, 10000); //maxbet - 3000

        this.setState({amount: max});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    setMin = (e) => {
        e.preventDefault();
        this.setState({amount: '10.00'});
        this.setTP(this.state.payout, 10);
        storage.setKey("amount", 10.00);
    };

    multi = (e) => {
        let max = this.state.amount * 2
        this.setState({amount: forceSatoshiFormat(max)});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    }

    devide = (e) => {
        let max = this.state.amount / 2
        max = Math.max(max, 10)
        this.setState({amount: forceSatoshiFormat(max)});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    }

    runMyAuto = (e) => {
        this.props.toggleSpecial(!this.state.autoRunning);

        if (this.state.autoRunning) {
            this.setState({autoRunning: false, autoHeader: 'Auto', autoBody: 'text-danger', autoColor: '#FFCF14', buttonText: 'BET (Next Round)'});
        } else {
            this.setState({autoRunning: true, autoHeader: 'Stop!', autoBody: 'spinner-border spinner-border-sm text-danger', autoColor: '#4FCF2F', buttonText: 'Automatic...'});
        }
    }

    checkAutoRunning = () => {
        if (this.state.autoRunning) {
            this.setWaitingButton();
            let amount = this.state.amount;
            let payout = this.state.payout;

            this.handleAuto({amount, payout});
        }
    }

    render() {
        let {amount, inputDisabled, amountDisabled, payout, buttonType, buttonText, hotkey, target_profit, winning_chance, autoHeader, autoBody, autoColor} = this.state;
        let {mobile, coin} = this.props;

        //initial render
        if (amount === "NaN") {
            amount = 10.00;
        }

        if (target_profit === '') {
            target_profit = parseFloat((amount * payout) - amount).toFixed(2);
            if (target_profit === 'NaN' || target_profit < '0') {
                target_profit = '~';
            } else {
                target_profit = toComma(target_profit);
            }
        }

        if (winning_chance === '') {
            winning_chance = parseFloat(99 / payout).toFixed(2);
            if (winning_chance == 'NaN' || winning_chance == 'Infinity') {
                winning_chance = '~';
            } else {
                winning_chance += '%';
            }
        }

        return (
            <div onKeyPress={(e) => this.handleHotKey(e)}>
                <form className="w-100 mt-1" onSubmit={(e) => {
                    this.handleBet(e)
                }}>

                    <div className={'row'}>
                        <div className={'col-6'}>
                            <div className={"form-group mb-1 bet-input payout"}>
                                <h6>Amount</h6>

                                <div className={"input-group"}>
                                    <input ref={this.wrapperRef} disabled={amountDisabled} type="number" min={10} step="0.01" className="form-control text-left" id="amount" name="amount" placeholder="..." value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange}
                                           onChange={this.handleInputChange}/>
                                    <div style={{'marginLeft': '-3px', width: '23%'}} className="input-group-append">
                                        <RangeCredit multi={this.multi} devide={this.devide} max={this.setMax} min={this.setMin} amountDisabled={amountDisabled}/>
                                    </div>
                                </div>
                                <p className={"text-warning mb-1 mt-1"} style={{fontSize: '0.7em'}}>[Target Profit: KES {target_profit}]</p>
                            </div>
                        </div>
                        <div className={'col-6'}>
                            <div className={"form-group mb-1 bet-input payout"}>
                                <h6>Auto Cashout [x]</h6>
                                <div className={"input-group"}>
                                    <div className="d-flex justify-content-around">
                                        <input style={{borderRadius: '0.25rem 0 0 0.25rem'}} disabled={amountDisabled} type="number" className="form-control text-left" id="payout" name="payout" max="99999" step="0.01" placeholder="[Optional]" value={payout} autoComplete={"off"}
                                               onKeyUp={this.handleInputChange} onChange={this.handleInputChange}/>
                                        <button type={"button"} onClick={this.runMyAuto} style={{backgroundColor: autoColor, borderRadius: '0 0.25rem 0.25rem 0'}} className={'btn btn-lg shadow-none h-100 p-0 pl-2 pr-2'}>
                                            <div>
                                                <h6 style={{color: 'black'}} className={"m-0"}>{autoHeader}</h6>
                                                <div className={autoBody}>{autoBody === 'text-danger' ? 'OFF' : ''}</div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <p className={"text-warning mb-1 mt-1"} style={{fontSize: '0.7em'}}>[Winning Chance: {winning_chance}]</p>
                            </div>
                        </div>
                    </div>

                    <table style={{'width': '100%'}}>
                        <tr>
                            <td className={"p-1"}>
                                <div className={"form-group rev mt-0 mb-0"}>
                                    <Button style={{fontFamily: 'Courier Prime', fontWeight: '700'}} variant={'btn btn-block ' + buttonType} disabled={inputDisabled} type="submit">
                                        {buttonText}
                                    </Button>
                                </div>
                                {!mobile &&
                                    <Col md={12} sm={12} className="m-auto">
                                        <div className="form-inline">
                                            <Col md={12} sm={12} className="font-12 text-grey">
                                                <span className={'badge badge-info rounded cp'}
                                                      onClick={() => this.hotkeyChange()}>
                                                    <span>HotKeys:</span> <span className={"label"}>{hotkey}</span>
                                                </span>
                                                <HotKey/>
                                            </Col>
                                        </div>
                                    </Col>
                                }
                            </td>
                        </tr>
                    </table>

                    {/*<hr className={"mt-0"}/>
                    <table className={"mb-2"} style={{'width': '100%'}}>
                        <tr>
                            <td className={"text-center"}>Target Profit<br/><b>{target_profit}</b></td>
                            <td className={"text-center"}>Winning Chance<br/><b>{winning_chance}</b></td>
                        </tr>
                    </table>*/}
                </form>
            </div>
        );
    }
}

class HotKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: 'pulse'
        };
    }

    toggleModal = (e) => {
        if (e !== undefined)
            e.preventDefault();

        this.setState({show: !this.state.show, effect: !this.state.show ? 'pulse' : 'zoomOut'});
    };

    render() {
        return (
            <>
                <button className={'btn btn-xs stc mt-1 pl-1'} onClick={e => this.toggleModal(e)}>
                    <i className={'cp mdi mdi-information text-info font-15'}/>
                </button>
                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.toggleModal}
                    aria-labelledby="help-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header>
                        Hot Keys
                        <button type="button" className="close p-1" onClick={e => this.toggleModal(e)}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body closeButton className="modal-helper text-center">
                        <Row className="shortcut-helper">
                            <Col sm="12">
                                <span className="badge badge-soft-danger">B</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> BET</span>
                            </Col>
                            <Col sm="12" className="my-2">
                                <span className="badge badge-soft-danger"> E</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> HALF PAYOUT</span>
                            </Col>
                            <Col sm="12">
                                <span className="badge badge-soft-danger"> F</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> DOUBLE PAYOUT</span>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

ManualBet.propTypes = {
    coin: PropTypes.string,
    im_in_game: PropTypes.bool,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    im_in_game: state.items.im_in_game,
    credit: state.items.credit
});

export default connect(mapStateToProps, {gameCoin, setWinnerText, setMeToGame, setWallet})(ManualBet);