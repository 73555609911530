import React from 'react'
import {Row, Col, Card} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {isMobile, wait, Event} from "../../../Helper";
import storage from "../../../Storage";
import Engine from "./Engine";
import Canvas from "./Graphic";
import MobileCanvas from "./Graphic/Mobile";
import HistoryList from "./includes/HistoryList";
import Bet from "./Bet";
import LowerPanel from "./LowerPanel";
import PlayersPanel from "./PlayersPanel";
import Login from "../../Pages/Auth/Login";
import Register from "../../Pages/Auth/Register";

class Index extends React.Component {
    _Mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            height: null,
            mobile: false,
            isLogged: false,
            token: false,
            padding: "p-1"
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);

        if (storage.getKey('token')) {
            this.setState({isLogged: true, token: storage.getKey('token')});
        }

        wait(500).then(() => {
            Engine.getStatus();
        });
    }

    componentWillMount() {
        wait(500).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({col: 'col-xl-12'});
            Event.emit('hide_games');
        } else {
            this.setState({col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({mobile: true});
        } else {
            this.setState({mobile: false});
        }

        if (isMobile()) {
            this.setState({padding: 'p-0', ovh: 'ovh'});
        }
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    render() {
        let token = storage.getKey('token');
        let {col, padding, mobile, ovh} = this.state;
        const {t} = this.props;

        let c_mobile = mobile ? '2.2rem' : '';

        return (
            <>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                        <Row className={"animated fadeIn"}>
                            <div style={{marginBottom: c_mobile}} className={"col-md-8 pr-md-1"}>
                                <div className={"row"}>
                                    <div className={"col-md-6 pr-1 pr-md-0 pl-1 mb-1"}>
                                        <div className={"animated pulse card"} style={{height: '100%'}}>
                                            {!mobile ?
                                                <Canvas mobile={this.state.mobile}/>
                                                :
                                                <MobileCanvas mobile={this.state.mobile}/>
                                            }
                                            <HistoryList game={'crash'} t={this.props.t}/>
                                        </div>
                                    </div>
                                    <div className={"col-md-6 pr-1 pl-1 mb-1"}>
                                        {(token !== null) &&
                                            <Bet mobile={this.state.mobile} token={this.state.token} isLogged={this.state.isLogged}/>
                                        }
                                        {(token === null) &&
                                            <Card className={"no-shadow mb-1 h-bet"} style={{height: '100%'}}>
                                                <Card.Body className="bet-form text-center no-shadow">
                                                    <div style={{display: 'inline-flex', alignItems: 'center'}}><Login t={t}/> or <Register t={t}/> to Play</div>
                                                </Card.Body>
                                            </Card>
                                        }
                                    </div>
                                </div>

                                <div className={"row"}>
                                    <div className={"col-md-12 pr-1 pl-1"}>
                                        <LowerPanel t={this.props.t}/>
                                    </div>
                                </div>
                            </div>
                            <div className={"d-none d-md-block col-md-4 pl-md-1  pr-md-2"}>
                                <div style={{'height': '100%', border: '3px solid #45025F'}} className={"card"}>
                                    <PlayersPanel/>
                                </div>
                            </div>
                        </Row>
                        :
                        <div className="game-loader text-center">
                            <div className={"spinner-grow text-info my-3"} role="status"/>
                        </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }

    showLogin() {
        Event.emit('show_login');
    }

    showRegister() {
        Event.emit('show_register');
    }
}

export default Index;