import React from 'react'
import PropTypes from "prop-types";
import {connect} from "react-redux";
import * as Cookies from "js-cookie";
import UserHeader from './Parts/Header/Header-User';
import GuestHeader from './Parts/Header/Header-Guest';
import Footer from './Parts/Footer';
import socket from "../../Socket";
import {Event, decode, encode, wait} from "../../Helper";
import WalletAlert from "../../App/Components/User/Wallet/Alert";
import C from "../../Constant";
import Login from "./Auth/Login";
import storage from "../../Storage";
import Engine from "../Games/Crash/Engine";

class Index extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: <GuestHeader t={this.props.t} location={this.props.location}/>,
            auth: false,
            banner_text: '',
            show_banner: false
        }
    }

    componentDidMount() {
        socket.on(C.ONLINE, (status) => this.loginUser(decode(status)));
        Engine.trigger.on("HAPPY_HOUR_BANNER", (data) => this.setHappyBanner(data));

        Event.on('showAuthModal', (status) => this.activeAuth(status));

        /**
         * Initialize Authentication
         */
        const sessionCookie = Cookies.get("auth");
        const here_token = storage.getKey('token');

        if (here_token !== null && sessionCookie) { //when a user already logged in
            socket.emit(C.ONLINE, encode({
                jwt_token: storage.getKey('jwt_token'), //fake
                user_token: storage.getKey('user_token'), //fake
                security_key: storage.getKey('security_key'), //fake
                secret_user: storage.getKey('secret_user'), //fake
                secret_realtime: storage.getKey('secret_realtime'), //fake
                client_system: storage.getKey('client_system'), //fake
                token_key: storage.getKey('token_key'), //fake
                secret_token: storage.getKey('secret_token'), //fake
                token: here_token, // REAL
                identifier: 'from index page',
            }));
        }
    }

    activeAuth = (status) => {
        this.setState({auth: status});
    }

    loginUser = (data) => {
        wait(500).then(() => {
            if (data.status === true) {

                this.setState({header: <UserHeader t={this.props.t} location={this.props.location}/>});

                Cookies.set("uid", data.id, {expires: 14});
                Cookies.set("auth", true, {expires: 14});

                storage.setKey('name', data.name);
                storage.setKey('email', data.email);
                storage.setKey('phone', data.phone);
                storage.setKey('avatar', data.avatar);
                storage.setKey('friends', data.friends);
                storage.setKey('room', data.room);
                storage.setKey('user_id', data.user_id);

                if (data.identifier === 'from login page') {
                    window.location.reload();
                }

                //bonus - happy hour
                if(data.plays_today === 0 || this.inHour(data.bonus_end_time)) {
                    this.setState({show_banner: true, banner_text: "Please place a Bet to start your Happy Hour"});
                }
            } else {
                wait(7000).then(() => {
                    localStorage.clear();
                })
            }
        })
    }

    setHappyBanner = (data) => {
        if(data !== '') {
            this.setState({show_banner: true, banner_text: data});
        }
    }

    inHour(bonus_end_time) {
        let unix_now = Math.floor(new Date().getTime() / 1000);
        let unix_bonus_end = (bonus_end_time === 0) ? unix_now + (3600) : Math.floor(new Date(bonus_end_time).getTime() / 1000);

        if (unix_now <= unix_bonus_end) { //bonus running
            return true;
        }

        return false;
    }

    render() {
        let {header, auth, banner_text, show_banner} = this.state;
        let {content} = this.props; // Pages / Games Contents
        let wallet; // Show Wallet if User don't Have Cash

        let if_show = show_banner ? '' : ' d-none';

        try {
            wallet = this.props.get_wallet.show;
        } catch (e) {
        }

        const {t} = this.props;
        return (
            <>
                {header}

                {wallet &&
                    <WalletAlert t={t} uid={this.props.get_wallet.uid}/>
                }
                {auth === true &&
                    <Login t={t} justModal="true"/>
                }
                {<div className={'text-center'} style={{backgroundImage: 'linear-gradient(#1c1d21, #520474)', borderRadius: '4px', marginBottom: '3px'}}>
                    <div style={{color: 'whitesmoke', fontSize: '10px', display:'inline-flex'}}><div style={{animation: 'spinner-border 4.15s linear infinite'}} className={"mdi mdi-gift text-success"}></div><b>&nbsp;Promotion:&nbsp;</b> Watch out for Happy Hour and get <b className={"text-success"}>&nbsp;+5%</b>&nbsp;Your Bet</div>
                    <p className={"m-0 text-success font-11" + if_show}>{banner_text}</p>
                </div>}
                {content}
                <Footer t={t}/>
            </>
        );
    }
}

Index.propTypes = {
    get_wallet: PropTypes.string
};

const mapStateToProps = state => ({
    get_wallet: state.items.get_wallet
});

export default connect(mapStateToProps, null)(Index);