import React from 'react'
import {Nav, NavItem} from 'reactstrap';
import {isMobile} from "../../../../Helper";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";

let show = true;

class Header extends React.Component {
    collapse = () => {
        show = !show;
    }

    render() {
        const {t} = this.props;
        return (
            <nav id={"topbar"} className="p-0 navbar navbar-expand-md navbar-light sticky-top" role="navigation">
                <div className="container-fluid pl-0 pr-0">
                    <a className="navbar-brand" href="/">
                        <span>
                                {isMobile() ?
                                    <img src="/assets/images/logo_mobile.svg" className="logo-mobile"/> :
                                    <img src="/assets/images/logo_jazabet.png" className="logo-sm"/>
                                }
                            </span>
                    </a>
                    <Nav className="ml-auto">
                        <NavItem>
                            <Login t={t}/>
                        </NavItem>
                        <NavItem>
                            <Register t={t}/>
                        </NavItem>
                    </Nav>
                </div>
            </nav>
        );
    }
}

export default Header;