import React from 'react'
import User from "./../User";
import {isMobile, Event} from "../../../../Helper";
import Wallet from "../../../Components/User/Wallet";
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import Logout from "../../Auth/Logout";
import Credit from "../Credit";
import Setting from "../../../Components/User/Setting";
import RealTime from "../RealTime";

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: null,
            show: true
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (!isMobile()) {
            this.setState({header: this.desktop()});
        } else {
            this.setState({header: this.mobile()});
        }
    }

    collapse = () => {
        this.setState({show: !this.state.show})
        Event.emit('toggle_sidebar', this.state.show);
    }

    showVerifyMpesa() {
        Event.emit('show_verify_mpesa');
    }

    desktop = () => {
        const {t} = this.props;

        //margin
        let logo_margin = '4rem';
        if (window.innerWidth <= 320) {
            logo_margin = '0.5rem';
        } else if (window.innerWidth > 320 && window.innerWidth < 425) {
            logo_margin = '2rem';
        }

        return (
            <nav id={"topbar"} className="p-0 navbar navbar-expand-md navbar-light" role="navigation">
                <div className="container-fluid pl-0 pr-0">
                    <RealTime t={t}/>
                    <a style={{marginRight: logo_margin}} className="navbar-brand" href="/">
                        <span><img src="/assets/images/logo_jazabet.png" className="logo-sm"/></span>
                    </a>
                    <Wallet t={t}/>
                    <NavLink to="#" className="nav-link btn-sm btn-warning ml-2" onClick={this.showVerifyMpesa}>{isMobile() ? '' : <i className={"fa fa-wallet text-danger"}/>} VERIFY M-PESA</NavLink>
                    <Nav className="ml-auto">
                        <NavItem>
                            <Credit t={t}/>
                        </NavItem>
                        <NavItem>
                            <NavLink to="/affiliate" className="nav-link"><i className={"fa fa fa-users"}></i> REFER & EARN</NavLink>
                        </NavItem>
                        <NavItem>
                            <Setting t={t}/>
                        </NavItem>
                        <NavItem>
                            <Logout t={t}/>
                        </NavItem>
                    </Nav>
                </div>
            </nav>
        );
    }

    mobile = () => {
        const {t} = this.props;

        //margin
        let logo_margin = '4rem';
        if (window.innerWidth <= 320) {
            logo_margin = '0.5rem';
        } else if (window.innerWidth > 320 && window.innerWidth < 425) {
            logo_margin = '2rem';
        }

        return (
            <nav id={"topbar"} className={"p-0 navbar navbar-expand-md navbar-light"} role={"navigation"}>
                <div className={"container-fluid pl-0 pr-0"}>
                    <RealTime t={t}/>
                    <a style={{marginRight: logo_margin}} className={"navbar-brand"} href={"/"}>
                        <span><img src="/assets/images/logo_jazabet.png" className="logo-mini"/></span>
                    </a>
                    <Wallet t={t}/>
                    <Nav className={"ml-auto"}>
                        <NavItem>
                            <Credit t={t}/>
                        </NavItem>
                        <NavItem>
                            <User t={t}/>
                        </NavItem>
                    </Nav>
                </div>
            </nav>
        );
    }

    render() {
        return this.state.header;
    }
}

export default Header;