import React, {Component} from 'react'
import {Modal} from "react-bootstrap";
import storage from "../../../../Storage";
import {sendNotfication, Event, isMobile} from "../../../../Helper";
import Wallet from "./Wallet";
import {NavLink} from "react-router-dom";

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            initState: 0
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('other_coins', () => {
            this.setState({show: false});
        })
        Event.on('update_payment', () => {
            this.setState({show: false});
        })
        Event.on('show_deposit', () => {
            this.setState({initState: 0});
            this.handleShow();
        })
        Event.on('show_verify_mpesa', () => {
            this.setState({initState: 1});
            this.handleShow();
        })
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    handleShow() {
        if (storage.getKey('token') === null) {
            return sendNotfication('Please Login to use this option.', 'warning', 'top-right');
        }

        this.setState({show: true, effect: 'pulse'});
    }

    render() {
        return (
            <>
                <NavLink to="#" className="nav-link btn-sm btn-warning" onClick={this.handleShow}>{isMobile()? '' : <i className={"fa fa-wallet"}/>} DEPOSIT</NavLink>

                <Modal size="md" backdrop={'static'} centered={true} scrollable={false} show={this.state.show} onHide={this.handleClose} aria-labelledby="wallet-md-modal" className={"animated " + this.state.effect}>
                    <Modal.Header>
                        Wallet
                        <button type="button" className="close p-2" onClick={this.handleClose}>
                            <i className={'mdi mdi-close'}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body className={'p-0 wallet-modal'}>
                        <Wallet initState={this.state.initState}/>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default Main;