import React from "react";
import {Card, Tab, Tabs} from "react-bootstrap";
import ManualBet from './includes/ManualBet';
import {isMobile, getUID} from "../../../Helper";
import Trenball from "./includes/Trenball";
import Engine from "./Engine";

class Bet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
            uid: getUID,
            classicDisabled: false,
            specialDisabled: false,
            playing_now: 0
        };

        this.toggleClassic = this.toggleClassic.bind(this);
        this.toggleSpecial = this.toggleSpecial.bind(this);
    }

    componentDidMount() {
        const engine = Engine;

        if (isMobile()) {
            this.setState({padding: 'p-1', margin: 'mt-1'})
        }

        engine.trigger.on("playing_now", (data) => this.setUsersPlaying(data));
    }

    toggleClassic(status) {
        this.setState({classicDisabled: status});
    }

    toggleSpecial(status) {
        this.setState({specialDisabled: status});
    }

    setUsersPlaying(data) {
        this.setState({playing_now: data});
    }

    render() {
        let {token, isLogged, mobile} = this.props;
        let {classicDisabled, specialDisabled, playing_now} = this.state;

        return (
            <Card className={"no-shadow mb-1 h-bet"} style={{height: '100%'}}>
                <Card.Body className="bet-form p-0 no-shadow">
                    <>
                        <Tabs fill defaultActiveKey="manual" id="uncontrolled-tab-bet">
                            <Tab disabled={classicDisabled} eventKey="manual" title="Jaza Classic">
                                <ManualBet toggleClassic={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                            </Tab>
                            <Tab disabled={specialDisabled} eventKey="special" title="Jaza Special">
                                <Trenball toggleClassic={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                            </Tab>
                        </Tabs>

                        <hr style={{borderColor: '#3B0054'}} className={"mt-0 mb-0"}/>
                        <div className={"row"}>
                            <div className={"col-6 text-center"}>ONLINE: <b id={"online_players"}>0</b></div>
                            <div className={"col-6 text-center"}>PLAYING: <b>{playing_now}</b></div>
                        </div>
                    </>
                </Card.Body>
            </Card>
        );
    }
}

export default Bet;