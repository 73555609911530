import React from 'react'
import {Table} from "react-bootstrap";
import storage from "../../../Storage";
import {encode, wait, fixDate} from "../../../Helper";
import C from "../../../Constant";
import socket from "../../../Socket";

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            list: []
        };
    }

    componentDidMount() {
        wait(1700).then(() => {
            this.setState({loading: false});
        })
        socket.emit(C.MY_AFF, encode({token: storage.getKey('token')}));
        socket.on(C.MY_AFF, data => this.makeList(data));
    }

    makeList = (data) => {
        this.setState({loading: false, list: data});
    }

    render() {

        const list = this.state.list.map((row, index) =>
            <List key={index} row={row}/>
        );

        return (
            <div className="table-responsive">
                {
                    this.state.loading ?
                        <div className="text-center">
                            <div class="spinner-border text-info my-3" role="status"/>
                        </div>
                        :
                        <>
                            {list.length === 0 ?
                                <>
                                    [No Referrals Yet]
                                </>
                                :
                                <Table className={"mb-2"}>
                                    <thead>
                                    <tr>
                                        <th>
                                            [REF]
                                        </th>
                                        <th>
                                            Username
                                        </th>
                                        <th>
                                            Date Registered
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {list}
                                    </tbody>
                                </Table>
                            }
                        </>
                }
            </div>
        );
    }
}

class List extends React.Component {
    render() {

        const {key, row} = this.props
        return (
            <tr>
                <td>
                    {row.id}
                </td>
                <td>
                    {row.username}
                </td>
                <td>
                    {fixDate(row.created)}
                </td>
            </tr>
        );
    }
}