import React from 'react';
import {Link} from "react-router-dom";
import UserAgreement from "../Sidebar/UserAgreement";
import Navigation from "../Navigation";

export default class Footer extends React.Component {
    
    userAgreement = () => {
        Event.emit('show_agreement');
    }

    privacyPolicy = () => {
        Event.emit('show_privacy');
    }

    verify = () => {
        Event.emit('show_verify');
    }

    render(){
        const { t } = this.props;
        return(
            <>
                <footer className={"d-none d-lg-block"} style={{margin:'0 12px'}}>
                    <p className="mt-1 mb-1 text-muted text-center"><Link to={'/leaderboard'}>Leaderboard</Link> | <Link to={'/affiliate'}>Refer & Earn</Link> | <Link to={'/happyhour'}>Happy Hour!</Link> | <UserAgreement t={t} /></p>
                    <p className="mt-1 mb-1 text-muted text-center">&copy; 2021 | JazaBet.com</p>
                </footer>
                <Navigation />
            </>
        );
    }
}