import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal, Row, Col} from "react-bootstrap";
import storage from "../../../../Storage";
import RangeCredit from "../../../Components/Game/Addons";
import Engine from "../Engine";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";
import {setWinnerText, setMeToGame} from "../../../../actions/crashGame";
import {Event, __, isValidNumber, forceSatoshiFormat, wait, Game, sendNotfication} from "../../../../Helper";

class Trenball extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonRed: 'Bet Red',
            buttonGreen: 'Bet Green',
            buttonMoon: 'Bet Moon',
            buttonTypeRed: 'btn-bet',
            buttonTypeGreen: 'btn-bet',
            buttonTypeMoon: 'btn-bet',
            inputDisabled: false,
            amountDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            payout: '',
            amount: storage.getKey('tren_amount') ? storage.getKey('tren_amount') : 100.00,
            token: storage.getKey('token') ? storage.getKey('token') : null,
            whichButton: '',
            trRunning: false
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.setBet = this.setBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine

            this.setState({engine: engine})

            //Load Coin From Redux
            this.props.gameCoin();

            document.addEventListener('mousedown', this.handleClickOutside);

            // Game Event
            engine.trigger.on("game_status", (data) => this.checkStatus(data));

            engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
            engine.trigger.on("busted_crash", () => this.checkBustedGame());
            engine.trigger.on("started_crash", () => this.checkStartedGame());

            // User Event
            engine.trigger.on("play_crash", data => this.handlePlay(data));
            engine.trigger.on("finish_crash", data => this.handleFinish(data));

            //Error
            //engine.trigger.on("error_crash", data => this.handleError(data));

            // Stop manual bet
            engine.trigger.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            engine.trigger.on("auto_bet", data => this.handleAuto(data));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            //amount
            let amount = this.wrapperRef.current.value;

            amount = Math.max(10.00, parseFloat(amount));
            if (amount > 3000) {
                //amount = 3000; //maxbet - 3000 | remember to also set in the input
            }

            this.setState({amount})

            if (amount === "NaN") {
                amount = 10.00;
            }
            this.setState({amount});
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (name === 'amount') {
            if (!isValidNumber(value)) return;

            storage.setKey("tren_amount", value);
        }

        this.setState({[name]: value});
    }

    setButtonTexts(newText, buttonClass) {
        switch (this.state.whichButton) {
            case 'red':
                this.setState({buttonRed: newText, buttonTypeRed: buttonClass});
                break;
            case 'green':
                this.setState({buttonGreen: newText, buttonTypeGreen: buttonClass});
                break;
            case 'moon':
                this.setState({buttonMoon: newText, buttonTypeMoon: buttonClass});
                break;
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    stopManual() {
        let disabled = this.state.trRunning ? true : false;
        this.props.toggleClassic(disabled);

        this.setButtonTexts('Bet ' + this.capitalizeFirstLetter(this.state.whichButton));
        this.setState({started: false, inputDisabled: disabled, amountDisabled: false});
    }

    checkStatus(data) {
        if (this._isMounted) {
            //console.log("game status, data:", data);

            switch (data.status) {
                case 'waiting':
                    this.checkWaitingGame();
                    break;
                case 'started':
                    wait(1000).then(() => {
                        this.checkStartedGame(data);
                    });
                    break;
                case 'busted':
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleError(data) {
        if (this._isMounted) {
            this.props.setMeToGame(false);

            let disabled = this.state.trRunning ? true : false;
            this.setState({holding: false, added: false, inputDisabled: disabled, amountDisabled: false});
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                if (data.code === 'credit')
                    this.props.setWallet(true, data.uid);
            }

            this.setState({clicked: false})
            sendNotfication(data.message, 'info', 'top-center');
        }
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);

            let disabled = this.state.trRunning ? true : false;
            this.props.toggleClassic(disabled);

            this.setButtonTexts('Bet ' + this.capitalizeFirstLetter(this.state.whichButton), 'btn-bet');
            this.setState({added: false, holding: false, inputDisabled: disabled, amountDisabled: false});
        }
    };

    setWaitingButton = () => {
        if (this._isMounted) {
            this.props.toggleClassic(true);

            this.setButtonTexts('Betting...', 'btn-bet-success-crash text-white btn-p no-shadow');
            this.setState({added: true, inputDisabled: true, amountDisabled: true});
        }
    };

    setOutButton = () => {
        if (this._isMounted) {
            this.props.toggleClassic(true);

            let disabled = this.state.trRunning ? true : false;

            this.setButtonTexts('Cancel', 'btn-bet-success');
            this.setState({inputDisabled: disabled, amountDisabled: true});
        }
    };

    setBet() {
        if (this._isMounted) {
            this.setState({clicked: true});
            let {engine, amount, payout, token, whichButton} = this.state;
            engine.coin = this.props.coin;
            engine.token = token;
            engine.amount = amount;
            engine.payout = payout;
            engine.type = 'trenball';
            engine.button = whichButton;
            engine.play();
        }
    }

    cashOut() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.finish(Game['current_amount']);
        }
    }

    handlePlay(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                this.props.setMeToGame(true);
            }
        }
    }

    handleFinish(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                clearInterval(this.state.buttonProgress);
                this.props.setWinnerText("  You Cashed Out @" + data.current / 100);
                this.props.setMeToGame(false);
                this.setDefaultButton();
            }
        }
    }

    checkWaitingGame() {
        if (this._isMounted) {
            this.props.setWinnerText('');
            //auto
            this.checkAutoRunning();

            this.setState({gameStatus: 'waiting'});

            if (this.state.holding) {
                this.setState({holding: false});
                this.placeBet();
            }
        }
    }

    checkStartedGame(data = null) {
        if (this._isMounted) {
            let {im_in_game} = this.props;
            let {engine} = this.state;

            let coin = engine.coin;
            let amount = engine.amount

            this.setState({gameStatus: 'started'});

            if (data != null) {
                //console.log("check started, players:", data.players.length);
                if (data.players.length > 0) {
                    data.players.forEach((the_player, i) => {
                        if (the_player.uid === 4153751612) {
                            //this.setState({clicked: true});
                        }
                        //console.log("check started, uid:", the_player.uid, ' >>', this.state.clicked);
                    });
                }
            }

            if (im_in_game === true || this.state.clicked) {
                this.props.toggleClassic(true);

                this.setState({inputDisabled: true, amountDisabled: true, clicked: false});

                let counter = 0;
                let self = this;
                this.state.buttonProgress = setInterval(function () {
                        //let calc = amount * (Game['current_amount'] - 1);
                        let current_amount = (Game['current_amount']);

                        self.setState({inputDisabled: true});

                        let calc = amount * current_amount; //Dan
                        this.setButtonTexts('Wait: ' + current_amount, 'btn-bet-success-crash');
                        //self.setState({buttonGreen: 'Wait: ' + current_amount});
                        counter++;
                    }
                        .bind(this),
                    50
                );
            }
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({gameStatus: 'busted', clicked: false});

            let {im_in_game} = this.props;

            if (!this.state.holding) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if (im_in_game) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    placeBet() {
        if (this._isMounted) {
            let {engine} = this.state;

            engine.isHolding = false;
            this.setWaitingButton();
            this.setBet();
        }
    }

    holdBet() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.isHolding = true;
            this.setState({holding: true});
            this.setOutButton();
        }
    }

    handleBet(e) {
        if (this._isMounted) {
            e.preventDefault();

            this.props.toggleClassic(true);

            let {amount, holding, gameStatus, token} = this.state;
            let {im_in_game} = this.props;

            // Check User
            if (!token) {
                return Event.emit('showAuthModal', true);
            }

            if (!isValidNumber(amount)) {
                return false;
            }

            if (amount < 10) {
                return false;
            }

            // Check Game Status to Play
            switch (gameStatus) {
                case 'waiting':
                    this.placeBet();
                    break;

                case 'busted':
                    if (holding) {
                        this.setDefaultButton();
                    } else this.holdBet();
                    break;

                case 'started':
                    if (im_in_game) {
                        //this.cashOut();
                    } else if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;
            }
        }
    }

    setMax = (e) => {
        e.preventDefault();
        let max = this.props.credit;
        if (max === null) return;
        if (max === 'NaN') return;

        max = Math.min(max, 10000); //maxbet - 3000

        this.setState({amount: max});
        storage.setKey("tren_amount", max);
    };

    setMin = (e) => {
        e.preventDefault();
        this.setState({amount: '10.00'});
        storage.setKey("tren_amount", 10.00);
    };

    multi = (e) => {
        let max = this.state.amount * 2
        this.setState({amount: forceSatoshiFormat(max)});
        storage.setKey("tren_amount", max);
    }

    devide = (e) => {
        let max = this.state.amount / 2
        max = Math.max(max, 10)
        this.setState({amount: forceSatoshiFormat(max)});
        storage.setKey("tren_amount", max);
    }

    runTrAuto = (e, button, payout) => {
        e.preventDefault();
        this.props.toggleClassic(!this.state.trRunning);

        //button
        this.setState({whichButton: button, payout: payout});

        if (this.state.trRunning) {
            this.setState({trRunning: false, inputDisabled: false, amountDisabled: false});
        } else {
            this.setState({trRunning: true, inputDisabled: true, amountDisabled: true});
        }
    }

    checkAutoRunning = () => {
        if (this.state.trRunning) {
            this.setWaitingButton();
            let amount = this.state.amount;
            let payout = this.state.payout;
            let button = this.state.whichButton;

            this.handleAuto({amount, payout, button});
        }
    }

    handleAuto = (data) => {
        if (this._isMounted) {

            let {amount, payout, button} = data;

            if (!payout) return alert('payout is empty.');
            if (!amount) return alert('amount is empty.');
            if (!button) return alert('button error.');

            this.setState({amount: amount, payout: payout, button: button});

            if (this.state.gameStatus === 'started') {
                this.holdBet();
            } else if (this.state.gameStatus === 'waiting' || this.state.gameStatus === 'busted') {
                this.setBet();
            }
        }
    };

    render() {
        let {amount, inputDisabled, amountDisabled, buttonTypeRed, buttonTypeGreen, buttonTypeMoon, buttonRed, buttonGreen, buttonMoon, whichButton, trRunning} = this.state;
        let [redHeader, moonHeader, greenHeader] = Array(3).fill('Auto');
        let [redBg, greenBg, moonBg] = Array(3).fill('#FFCF14');
        let redBody = 'special-danger', greenBody = 'special-green', moonBody = 'special-yellow';
        let [redDisabled, greenDisabled, moonDisabled] = Array(3).fill(false);

        switch (whichButton) {
            case 'red':
                redHeader = trRunning ? 'Stop!' : redHeader;
                redBg = trRunning ? '#4FCF2F' : redBg;
                redBody = trRunning ? 'spinner-border spinner-border-sm special-danger' : redBody;
                [greenDisabled, moonDisabled] = Array(2).fill(trRunning);
                break;
            case 'green':
                greenHeader = trRunning ? 'Stop!' : greenHeader;
                greenBg = trRunning ? '#4FCF2F' : greenBg;
                greenBody = trRunning ? 'spinner-border spinner-border-sm special-danger' : greenBody;
                [redDisabled, moonDisabled] = Array(2).fill(trRunning);
                break;
            case 'moon':
                moonHeader = trRunning ? 'Stop!' : moonHeader;
                moonBg = trRunning ? '#4FCF2F' : moonBg;
                moonBody = trRunning ? 'spinner-border spinner-border-sm special-danger' : moonBody;
                [greenDisabled, redDisabled] = Array(2).fill(trRunning);
                break;
        }

        //initial render
        if (amount === "NaN") {
            amount = 10.00;
        }

        return (
            <div>
                <form className="w-100 mt-1" onSubmit={(e) => {
                    this.handleBet(e)
                }}>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <div className={"form-group mb-1 bet-input payout"}>
                                <h6>Bet Amount</h6>
                                <div className={"input-group"}>
                                    <input ref={this.wrapperRef} disabled={amountDisabled} type="number" min={10} step="0.01" className="form-control text-left" id="amount" name="amount" placeholder="..." value={amount} autoComplete={"off"} onKeyUp={this.handleInputChange}
                                           onChange={this.handleInputChange}/>
                                    <div style={{'marginLeft': '-3px', width: '14%'}} className="input-group-append">
                                        <RangeCredit multi={this.multi} devide={this.devide} max={this.setMax} min={this.setMin} amountDisabled={amountDisabled}/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={"row"}>
                        <div className={"pr-0 col-4"}>
                            <Button name={"red"} onClick={() => (this.state.whichButton = 'red', this.state.payout = 170)} style={{padding: '3px 1px', fontFamily: 'Courier Prime', fontWeight: '700', lineHeight: '0.9'}} variant={'btn btn-block ' + buttonTypeRed} disabled={inputDisabled}
                                    type="submit">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <div style={{fontSize: '0.85em'}}>&lt;2</div>
                                    <b style={{color: 'red'}}>{buttonRed}</b>
                                    <div style={{fontSize: '0.65em'}}>(Payout 1.70x)</div>
                                </div>
                            </Button>
                            <Button name={"red-auto"} onClick={e => (this.runTrAuto(e, 'red', 170))} style={{backgroundColor: redBg, maxHeight: '21px', marginTop: '0.2em', padding: '3px 1px', fontFamily: 'Courier Prime', fontWeight: '400', lineHeight: '0.9'}}
                                    variant={'btn btn-block ' + buttonTypeRed} disabled={redDisabled} type={'button'}>
                                {redHeader} <b className={redBody}>{redBody === 'special-danger' ? 'Red' : ''}</b>
                            </Button>
                        </div>

                        <div className={"pl-1 pr-1 col-4"}>
                            <Button name={"green"} onClick={() => (this.state.whichButton = 'green', this.state.payout = 200)} style={{padding: '3px 1px', fontFamily: 'Courier Prime', fontWeight: '700', lineHeight: '0.9'}} variant={'btn btn-block ' + buttonTypeGreen} disabled={inputDisabled}
                                    type="submit">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <div style={{fontSize: '0.85em'}}>&ge;2</div>
                                    <b style={{color: 'green'}}>{buttonGreen}</b>
                                    <div style={{fontSize: '0.65em'}}>(Payout 2x)</div>
                                </div>
                            </Button>
                            <Button name={"green-auto"} onClick={e => (this.runTrAuto(e, 'green', 200))} style={{backgroundColor: greenBg, maxHeight: '21px', marginTop: '0.2em', padding: '3px 1px', fontFamily: 'Courier Prime', fontWeight: '400', lineHeight: '0.9'}}
                                    variant={'btn btn-block ' + buttonTypeGreen} disabled={greenDisabled} type={'button'}>
                                {greenHeader} <b className={greenBody}>{greenBody === 'special-green' ? 'Green' : ''}</b>
                            </Button>
                        </div>

                        <div className={"pl-0 col-4"}>
                            <Button name={"moon"} onClick={() => (this.state.whichButton = 'moon', this.state.payout = 1000)} style={{padding: '3px 1px', fontFamily: 'Courier Prime', fontWeight: '700', lineHeight: '0.9'}} variant={'btn btn-block ' + buttonTypeMoon} disabled={inputDisabled}
                                    type="submit">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <div style={{fontSize: '0.85em'}}>&ge;10</div>
                                    <b style={{color: 'yellow'}}>{buttonMoon}</b>
                                    <div style={{fontSize: '0.65em'}}>(Payout 10x)</div>
                                </div>
                            </Button>
                            <Button name={"moon-auto"} onClick={e => (this.runTrAuto(e, 'moon', 1000))} style={{backgroundColor: moonBg, maxHeight: '21px', marginTop: '0.2em', padding: '3px 1px', fontFamily: 'Courier Prime', fontWeight: '400', lineHeight: '0.9'}}
                                    variant={'btn btn-block ' + buttonTypeMoon} disabled={moonDisabled} type={'button'}>
                                {moonHeader} <b className={moonBody}>{moonBody === 'special-yellow' ? 'Moon' : ''}</b>
                            </Button>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-12 mt-2"}>
                            <table className={"table table-striped mb-0"}>
                                <tr>
                                    <td style={{verticalAlign: 'top', color: 'red'}}>Red:</td>
                                    <td style={{fontSize: '0.9em'}}>&#10004; Crash is less than 2<br/>&#10004; <span className={"text-warning"}>50.5%</span> you will get <span className={"text-success"}>1.70x</span> of your Bet</td>
                                </tr>
                                <tr>
                                    <td style={{verticalAlign: 'top', color: 'green'}}>Green:</td>
                                    <td style={{fontSize: '0.9em'}}>&#10004; Crash is equal to or more than 2<br/>&#10004; <span className={"text-warning"}>49.5%</span> you will get <span className={"text-success"}>2x</span> of your Bet</td>
                                </tr>
                                <tr>
                                    <td style={{verticalAlign: 'top', color: 'yellow'}}>Moon:</td>
                                    <td style={{fontSize: '0.9em'}}>&#10004; Crash is equal to or more than 10<br/>&#10004; <span className={"text-warning"}>9.9%</span> you will get <span className={"text-success"}>10x</span> of your Bet</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

Trenball.propTypes = {
    coin: PropTypes.string,
    im_in_game: PropTypes.bool,
    credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    im_in_game: state.items.im_in_game,
    credit: state.items.credit
});

export default connect(mapStateToProps, {gameCoin, setWinnerText, setMeToGame, setWallet})(Trenball);