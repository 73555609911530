import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";
import {Table} from "react-bootstrap";
import SwipeableViews from 'react-swipeable-views';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import socket from "../../../Socket";
import storage from "../../../Storage";
import UserModal from "../../Components/User/Stat/Modal";
import {__, fixDate, Event, isMobile, wait, decode, encode, formatAmount, timeConvertor} from "../../../Helper";
import C from "../../../Constant";
import History from "./History";
import Engine from "./Engine";
import Chat from "../../Pages/Parts/Chat";
import PlayersPanel from "./PlayersPanel";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    tab: {
        overflowX: 'hidden',
    },
}));


function LowerPanel(props) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    let {game} = props;
    let token = storage.getKey('token');
    const {t} = props;

    const engine = Engine;

    function load() {
        wait(500).then(() => {
            engine.getPlayers();
        });
    }

    let font_size = '11px'; //inherit
    if (isMobile()) {
        font_size = '11px';
    }

    return (
        <div className={classes.root + " card pr-1 pl-1"}>
            <AppBar position="static" color="transparent">
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" variant="fullWidth" aria-label="full width tabs" centered>
                    <Tab style={{fontSize: font_size, whiteSpace: 'nowrap'}} icon={<i className={'mdi mdi-chart-bar'}/>} onClick={load} label="Players" {...a11yProps(0)} />
                    <Tab style={{fontSize: font_size, whiteSpace: 'nowrap'}} icon={<i className={'mdi mdi-message-text'}/>} label="Chat" {...a11yProps(1)} />
                    <Tab style={{fontSize: font_size, whiteSpace: 'nowrap'}} icon={<i className={'mdi mdi-chart-areaspline'}/>} label="My Bets" {...a11yProps(2)} />
                    <Tab style={{fontSize: font_size, whiteSpace: 'nowrap'}} icon={<i className={'mdi mdi-chart-donut'}/>} label="History" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <div className={"mb-2 p-1"} style={{border: "2px solid #520474", borderRadius: "0px 0px 5px 5px", minHeight: '200px'}}>
                <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                    <TabPanel value={value} index={0} dir={theme.direction}>
                        <div style={{maxHeight: '240px', minHeight:'240px'}} className={"dan-scrollbar"}>
                            <PlayersPanel/>
                        </div>
                    </TabPanel>
                    <TabPanel value={value} index={1} dir={theme.direction}>
                        <Chat t={t}/>
                    </TabPanel>
                    <TabPanel value={value} index={2} dir={theme.direction}>
                        {(token !== null) &&
                            <MyBets engine={engine} t={props.t} game={game}/>
                        }
                        {(token === null) &&
                            <div className={'alert bg-soft-purple mt-2 font-13 text-white text-center'}>You must be logged to see your stats</div>
                        }
                    </TabPanel>
                    <TabPanel className={classes.tab} value={value} index={3} dir={theme.direction}>
                        <History tab={true} engine={engine} t={props.t} game={game}/>
                    </TabPanel>
                </SwipeableViews>
            </div>
        </div>
    );
}

class MyBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            players: [],
            game: 'crash',
            token: storage.getKey('token'),
            name: storage.getKey('name'),
            gameCode: md5('crash')
        };
    }

    componentDidMount() {
        socket.emit(C.MY_BETS, encode({token: this.state.token, game: this.state.game}));
        socket.on(C.MY_BETS, data => this.makeList(decode(data)));
        socket.on(C.ADD_BET, data => this.addList(decode(data)));
    }

    addList(player) {
        if (player.name === this.state.name) {
            let row = <OwnBets forMy={true} player={player}/>;
            this.setState(state => ({players: [row, ...state.players]}));
        }
    }

    makeList(arr) {
        if (typeof arr.history === "undefined") return;
        this.setState({loading: false});
        arr.history.forEach((player, i) => {
            let row = <OwnBets key={i} forMy={true} player={player}/>;
            this.setState(state => ({players: [row, ...state.players]}));
        });
    }

    render() {
        if (this.state.players.length !== 0)
            this.state.players.length = 10;

        return (
            <>
                <div className={'dan-scrollbar table-responsive last-bets num-style mb-0'}>
                    {this.state.loading ?
                        <>
                            <div className="text-center">
                                <div className="spinner-grow text-white my-3" role="status"/>
                            </div>
                        </>
                        :
                        <>
                            <Table className={"mb-0 my_bets"}>
                                <thead>
                                <tr>
                                    <th>
                                        [REF]
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                    <th>
                                        Cashout
                                    </th>
                                    <th>
                                        Profit
                                    </th>
                                    <th>
                                        Date/Time
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.players}
                                </tbody>
                            </Table>
                            {
                                (this.state.players.length === 0) &&
                                <>
                                    <div className={'alert text-uppercase bg-cs mt-2 font-13 text-white text-center'}>Not Played Yet</div>
                                </>
                            }
                        </>

                    }
                </div>
            </>
        );
    }
}

class OwnBets extends React.Component {
    gameDetail = () => {
        Event.emit('single_game_modal', {data: this.props.player})
    }

    render() {
        let {name, amount, cashout, result, profit, coin, created, gid, direct, forMy, type, option} = this.props.player;
        let isWinner = false;
        let date = fixDate(created);
        type = (type === 's') ? 'JS-' + option : 'JC';

        if (direct) {
            date = timeConvertor(created);
        }

        if (__.toNumber(profit) !== 0.00000000) {
            isWinner = true;
        }

        if (cashout === '' || cashout == null) {
            cashout = 'Busted';
        } else {
            cashout = '@' + cashout;
        }

        return (
            <tr className={'q-crash cpt'}>
                <td onClick={this.gameDetail}>
                    {gid}
                </td>
                {forMy === false &&
                    <td>
                        <UserModal username={name} isWinner={isWinner}/>
                    </td>
                }
                <td onClick={this.gameDetail} className={(isWinner === true) ? 'text-success-2 num-style' : 'num-style'}>
                    <img src={'/assets/images/' + coin + '.png'} className={'mini-coin-2 hidden-sm'} alt='KES'/>
                    {formatAmount(amount, 0)} <small>({type})</small>
                </td>
                <td onClick={this.gameDetail} className={(isWinner === true) ? 'text-success-2 num-style' : 'num-style'}>
                    <small>{cashout}</small> / {result}
                </td>
                <td onClick={this.gameDetail} className={(isWinner === true) ? 'text-success-2 num-style' : 'num-style'}>
                    <img src={'/assets/images/' + coin + '.png'} className={'mini-coin-2 hidden-sm'} alt='KES'/>
                    {formatAmount(profit, 0)}
                </td>
                <td onClick={this.gameDetail}>
                    {date}
                </td>
            </tr>
        );
    }
}

function sortByAmount(input) {
    function r(c) {
        return c.amount ? -c.amount : null;
    }

    return __.sortBy(input, r);
}

function sortByCashout(input) {
    function r(c) {
        return c.current ? -c.current : null;
    }

    return __.sortBy(input, r);
}

export default LowerPanel;