import React from 'react';
import {Nav, NavItem} from 'reactstrap';
import {NavLink} from 'react-router-dom';
import {Event} from '../../../Helper';
import Engine from "../../Games/Crash/Engine";

class Navigation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {bonus: '0'};
    }

    showDeposit() {
        Event.emit('show_deposit');
    }

    showVerifyMpesa() {
        Event.emit('show_verify_mpesa');
    }

    updateBonus(data) {
        this.setState({bonus: data});
    }

    componentDidMount() {
        Engine.trigger.on("update_bonus", (data) => this.updateBonus(data));
    }

    render() {

        let {bonus} = this.state;

        return (
            <div>
                {/*Bottom*/}
                <nav style={{padding: '0.1rem 0.2rem'}} className={"navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav"} role={"navigation"}>
                    <Nav style={{background: 'transparent'}} className={"w-100"}> {/*<div className={"d-flex flex-row justify-content-around w-100"}>*/}
                        <NavItem style={{width: '19%'}} className={"bottom-nav-buttons"} key={`tab-1`}>
                            <NavLink to={'#'} className="nav-link bottom-nav-link">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <i className={"fa fa-shopping-bag"}/>
                                    <div onClick={this.showDeposit} className={"bottom-tab-label font-11 font-11"}>Deposit</div>
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{width: '19%'}} className={"bottom-nav-buttons"} key={`tab-2`}>
                            <NavLink to={'#'} className="nav-link bottom-nav-link">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <i className={"fa fa-check text-success"}/>
                                    <div onClick={this.showVerifyMpesa} className={"bottom-tab-label font-11"}>Verify <small className={"text-success"}>MPESA</small></div>
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{width: '22%'}} className={"bottom-nav-buttons"} key={`tab-3`}>
                            <NavLink to={"/happyhour"} className="nav-link bottom-nav-link">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    Happy Hour
                                    <div className={"bottom-tab-label font-10 text-warning text-nowrap"}>KES {bonus}</div>
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{display: 'none'}} className={"bottom-nav-buttons"} key={`tab-4`}>
                            <NavLink to={"/earners"} className="nav-link bottom-nav-link">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <i className={"fa fa-trophy text-warning"}/>
                                    <div className={"bottom-tab-label font-11 text-warning"}>Win 100k</div>
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{width: '19%'}} className={"bottom-nav-buttons"} key={`tab-5`}>
                            <NavLink to={"/affiliate"} className="nav-link bottom-nav-link">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <i className={"fa fa-shopping-cart"}/>
                                    <div className={"bottom-tab-label font-11"}>Refer & Earn</div>
                                </div>
                            </NavLink>
                        </NavItem>

                        <NavItem style={{width: '19%'}} className={"bottom-nav-buttons"} key={`tab-6`}>
                            <NavLink to={"/leaderboard"} className="nav-link bottom-nav-link">
                                <div className={"row d-flex flex-column justify-content-center align-items-center"}>
                                    <i className={"fa fa-gift"}/>
                                    <div className={"bottom-tab-label font-11"}>Top Users</div>
                                </div>
                            </NavLink>
                        </NavItem>
                    </Nav>
                </nav>
            </div>
        );
    }
};
export default Navigation;