import React from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Dropdown} from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import storage from "../../../../Storage";
import {setCoin, gameCoin, setCredit} from "../../../../actions/gameCoin";
import {__, wait, decode, encode, forceSatoshiFormat, Event, COIN_TOKEN, formatAmount} from "../../../../Helper";
import C from "../../../../Constant";
import {NavLink} from "react-router-dom";
import Engine from "../../../Games/Crash/Engine";

class Credit extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            token: storage.getKey('token'),
            coin: COIN_TOKEN,
            selectedCoin: COIN_TOKEN,
            credits: {},
            coins: [],
            different: [],
            credit: false
        };
        this.selectCoin = this.selectCoin.bind(this);
    }

    componentDidMount() {
        //Get Redux Coin
        this.props.gameCoin();

        if (this.state.token !== null)
            socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.coin}));

        socket.on(C.CREDIT, data => this.getCredit(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.updateCredit(decode(data)));
    }

    updateAllCoins() {
        this.setState({coins: []});

        coins.forEach((item, key) => {
            let style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
            let value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
            let coin = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                <div className="float-left">
                    <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'}/>
                    {item.preffix}
                </div>
                <div className="float-right">{value}</div>
            </Dropdown.Item>;
            this.setState(state => ({coins: [coin, ...state.coins]}));
        });

    }

    getCredit(data) {
        let {credit} = data;

        this.setState({credits: credit});

        let currentCoin = this.state.coin;
        let currentCredit = forceSatoshiFormat(this.state.credits[currentCoin]);

        let bonus = this.state.credits['bonus'];
        Engine.trigger.emit('update_bonus', bonus);

        this.setState({credit: currentCredit});
        storage.setKey('credit', currentCredit);

        //Set for Redux
        this.props.setCredit(currentCredit);
        this.updateAllCoins();
    }

    extractTime(ending_time) {
        let unix = Math.floor(new Date(ending_time).getTime()/1000);
        const milliseconds = unix * 1000;
        const dateObject = new Date(milliseconds);
        return dateObject.toLocaleTimeString();
    }

    updateBonuses(bonus, bet_bonus, bonus_end_time) {
        Engine.trigger.emit('update_bonus', bonus);

        //banner
        let unix_now = Math.floor(new Date().getTime() / 1000);
        let unix_bonus_end = (bonus_end_time === 0) ? unix_now + (3600) : Math.floor(new Date(bonus_end_time).getTime() / 1000);

        if (unix_now <= unix_bonus_end) { //bonus running
            let remaining = Math.floor((unix_bonus_end - unix_now) / 60);
            Engine.trigger.emit('HAPPY_HOUR_BANNER', remaining + " Minutes of Happy Hour! Earned: " + bonus + " | Bet Bonus: " + bet_bonus);
        } else {
            Engine.trigger.emit('HAPPY_HOUR_BANNER', "");
        }
    }

    updateCredit(data) {
        let credit = data.value;
        let coin = data.coin;
        let bonus = data.bonus;
        let bet_bonus = data.bet_bonus;
        let bonus_end_time = data.bonus_end_time;

        this.updateBonuses(bonus, bet_bonus, bonus_end_time);

        let different;
        let arr;

        if (__.toNumber(credit) > __.toNumber(this.state.credit)) {
            different = __.toNumber(credit) - __.toNumber(this.state.credit);
            arr = {
                amount: different,
                color: 'text-success'
            }
        } else {
            different = __.toNumber(this.state.credit) - __.toNumber(credit);
            arr = {
                amount: different,
                color: 'text-danger'
            }
        }

        let check = forceSatoshiFormat(different);

        if (check.toString() !== '0.00000000') {
            this.setState(state => ({different: [arr, ...state.different]}));
        }

        this.setState({credit: forceSatoshiFormat(credit)});
        storage.setKey('credit', credit);
        this.updateParentCoin(__.lowerCase(coin), credit);

        //Set for Redux
        this.props.setCredit(forceSatoshiFormat(credit));
    }

    updateParentCoin(coin, amount) {
        this.setState({coins: []});

        coins.forEach((item, key) => {
            if (__.lowerCase(item.preffix) === coin) {
                const style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                let update = <Dropdown.Item onClick={e => this.selectCoin(__.upperCase(coin))} key={key} className={"num-style " + style}>
                    <div className="float-left">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'}/>
                        {item.preffix}
                    </div>
                    <div className="float-right">{forceSatoshiFormat(amount)}</div>
                </Dropdown.Item>;
                this.setState(state => ({coins: [update, ...state.coins]}));
            } else {
                const style = __.lowerCase(item.preffix) === __.lowerCase(this.state.selectedCoin) ? 'actc' : '';
                let value = forceSatoshiFormat(this.state.credits[__.lowerCase(item.preffix)]);
                let update = <Dropdown.Item onClick={e => this.selectCoin(item.preffix)} key={key} className={"num-style " + style}>
                    <div className="float-left">
                        <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-7'}/>
                        {item.preffix}
                    </div>
                    <div className="float-right">{value}</div>
                </Dropdown.Item>;
                this.setState(state => ({coins: [update, ...state.coins]}));
            }
        });
    }

    selectCoin(name) {
        //Fix For Double selection
        if (storage.getKey('coin') === name) return;
        storage.setKey('coin', name);

        let credit = this.state.credits[__.lowerCase(name)];
        this.setState({coin: name, credit: credit, selectedCoin: name});

        //Set Coin For Redux
        this.props.setCoin(name);
        this.props.setCredit(credit);

        wait(200).then(() => {
            this.updateAllCoins();
            Event.emit('coin_changed');
        })
    }

    addDiff(data, i) {
        let id = 'id_' + Math.floor(Math.random() * 1000 + 1);

        wait(2000).then(() => {
            try {
                document.getElementById(id).classList.remove('frd');
                document.getElementById(id).classList.add('fadeOutDown');
            } catch (e) {
            }
            this.state.different.splice(i, 1);
        });

        return <li key={i} id={id} className={'list-inline w-100 text-right animated frd ' + data.color}> {(data.color === 'text-danger' ? '-' : '+')}
            {forceSatoshiFormat(data.amount, this.state.coin)}
        </li>;
    }

    open = () => {
        this.setState({show: !this.state.show});
    }

    showDeposit() {
        Event.emit('show_deposit');
    }

    render() {
        let {credit, different, coin, coins, show} = this.state;
        //credit = forceSatoshiFormat(credit, coin);
        credit = formatAmount(credit);
        let diff = different.map((data, i) =>
            this.addDiff(data, i)
        );

        const style = show ? 'show' : 'd-nones';
        const {t} = this.props;
        return (
            <>
                <NavLink style={{border: '1px solid #45025F', borderRadius: '4px'}} onClick={this.showDeposit} to="#" className="nav-link">
                    <div className="crypto-balance text-center">
                        <span id="cup2" className={'mt-2 mr-1'}>{diff}</span>
                        <div className="btc-balance">
                            KES <b>{credit}</b>
                        </div>
                    </div>
                </NavLink>
            </>
        );
    }
}

Credit.propTypes = {
    setCoin: PropTypes.func.isRequired,
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, {setCoin, gameCoin, setCredit})(Credit);